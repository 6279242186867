import React, { useEffect } from 'react'
import { navigate } from "gatsby";
import { isMobile, isBrowser} from 'react-device-detect';

import werkstattBild from '../images/werkstatt01.jpg'
//import geigenBild from '../images/geigen.jpg'
import portrait from '../images/me.jpg'
import guteSiegel from '../images/gutesiegel_300dpi.jpg'

import Layout from '../components/Layout/Layout.js'
import './index.module.css'

import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <div>{children}</div>
    )
  }
};

const IndexPage = ({ data }) => {
  useEffect(() => {
    if (isMobile) {
        navigate(`/mobile/`, {replace: true})
    }
  }, []);

  const indexContent = data.contentfulIndexContent;

  return (
    <Layout>
      <div styleName={'homePage'}>
        <div styleName={'homeImage'}>
          <img src={werkstattBild} alt='Werkstatt'/>
           <div styleName={'siteTitleContainer'}>
            <div styleName={'fontTop'}>
              Walter Neubauer
            </div>
            <div styleName={'fontBottom'}>
              Geigenbaumeister
            </div>
          </div>
        </div>

        <div styleName={'homeContent'}>
          <div styleName={'portraitImg'}>
            <img src={portrait} alt={'Portrait'} />
          </div>
          <div styleName={'welcomeText'}>
            {documentToReactComponents(indexContent.welcomeText.json)}
          </div>
        </div>
        <div styleName={'memberships'}>
          <div>
            {documentToReactComponents(indexContent.membershipsText.json, options)}
          </div>
          <div styleName={'membershiplogo'}>
            <img src={guteSiegel} alt={'Meisterguetesiegel'} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const indexQueryFragment = graphql `
  fragment IndexQueryFragment on ContentfulIndexContent {
    membershipsText {
      json
    }
    welcomeText {
      json
    }
  }
`

export const indexQuery = graphql `
  query indexDEQuery {
    contentfulIndexContent(node_locale: {eq: "de"}) {
      ...IndexQueryFragment
    }
  }
`
